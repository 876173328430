import request from '@/utils/request'

export function queryScoreLogList (data) {
  return request({
    url: '/activity/experienceScore',
    data
  })
}

export function getErrorInfo (data) {
  return request({
    url: '/log/getErrorInfo',
    baseURL: process.env.VUE_APP_LOG_BASEURL,
    data
  })
}

export function connectResourceErr (data) {
  return request({
    url: '/log/connectResourceErr',
    baseURL: process.env.VUE_APP_LOG_BASEURL,
    data
  })
}

export function topUpErr (data) {
  return request({
    url: '/log/topUpErr',
    baseURL: process.env.VUE_APP_LOG_BASEURL,
    data
  })
}

export function loginErr (data) {
  return request({
    url: '/log/loginErr',
    baseURL: process.env.VUE_APP_LOG_BASEURL,
    data
  })
}
export function streamDeskErr (data) {
  return request({
    url: '/log/streamDeskErr',
    baseURL: process.env.VUE_APP_LOG_BASEURL,
    data
  })
}
export function interfaceErr (data) {
  return request({
    url: '/log/interfaceErr',
    baseURL: process.env.VUE_APP_LOG_BASEURL,
    data
  })
}

export function getConnectInfo (data) {
  return request({
    url: '/gear/getConnectInfo',
    data
  })
}

export function getUserLinkStatistics (data) {
  return request({
    url: '/log/admin/statics/conn/time',
    baseURL: process.env.VUE_APP_LOG_BASEURL,
    data
  })
}

export function getSankeyeData (data) {
  return request({
    url: '/log/admin/statics/sankey',
    baseURL: process.env.VUE_APP_LOG_BASEURL,
    data
  })
}

export function getMachineRecord (data) {
  return request({
    url: '/log/admin/resource/history',
    baseURL: process.env.VUE_APP_LOG_BASEURL,
    data
  })
}

export function getMachineChart (data) {
  return request({
    url: '/log/admin/statics/machine',
    baseURL: process.env.VUE_APP_LOG_BASEURL,
    data
  })
}
