<template>
  <Layout class="Dashboard-layout">
    <BHeader>
      <el-form
        :model="formData"
        :inline="true"
        :rules="rules"
      >
        <el-row>
          <el-col :span="24">
            <el-form-item>
              <el-date-picker
                v-model="formData.dateRange"
                type="datetimerange"
                :clearable="false"
                format="yyyy-MM-dd HH:mm"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
              />
            </el-form-item>
            <el-form-item>
              <el-select
                v-model="formData.type"
                placeholder="请选择"
              >
                <el-option
                  v-for="item in options"
                  :key="item.value"
                  :label="item.name"
                  :value="item.value"
                />
              </el-select>
            </el-form-item>
            <el-form-item>
              <el-select
                v-model="formData.gameId"
                filterable
                remote
                clearable
                reserve-keyword
                placeholder="选择游戏"
                :remote-method="remoteMethod"
                :loading="remoteLoading"
              >
                <el-option
                  v-for="item in options"
                  :key="item.gameId"
                  :label="item.gameName"
                  :value="item.gameId"
                />
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col>
            <el-form-item
              label="查询指定版本号"
              prop="appVersion"
            >
              <el-select
                v-model="formData.plat"
                placeholder="平台"
                clearable
              >
                <el-option
                  v-for="item in gamePlatList"
                  :key="item.value"
                  :label="item.name"
                  :value="item.value"
                />
              </el-select>
              <el-select
                v-model="formData.appVersionCompare"
                style="width: 80px; margin: 0 10px;"
                placeholder="条件"
                clearable
              >
                <el-option
                  v-for="item in computeSignList"
                  :key="item.value"
                  :label="item.name"
                  :value="item.value"
                />
              </el-select>
              <el-input
                v-model="formData.appVersion"
                style="width: 180px"
                placeholder="格式: 115"
                clearable
              />
            </el-form-item>
            <el-form-item>
              <el-button
                type="primary"
                @click="queryMachineConnectRate"
              >
                查询
              </el-button>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <template #right>
        <el-button
          type="text"
          @click="handleViewLog"
        >
          查看用户连接时长统计
        </el-button>
      </template>
    </BHeader>
    <div
      v-loading="loading"
      class="wrapper"
    >
      <SankeyChart
        ref="chart"
        height="auto"
        :chart-data="chartData"
      />
    </div>
  </Layout>
</template>

<script>
// import { queryMachineConnectRate } from '@/api/StreamingDesktop.js'
import SankeyChart from '@/views/resources/streaming-desktop-v2/components/SankeyChart'
import { format } from 'fecha'
import { getSankeyeData } from '@/api/log.js'
import { searchGameList } from '@/api/gameList'
import {
  gamePlatList
} from '@/utils/selectOptions.js'

const dateFormat = 'YYYY-MM-DD HH:mm:ss'

const computeSignList = [
  { name: '>=', value: '>=' },
  { name: '=', value: '=' },
  { name: '<=', value: '<=' }
]
export default {
  name: 'Dashboard',
  components: { SankeyChart },
  data () {
    const appVersionCheck = (rule, value, callback) => {
      const { plat, appVersionCompare, appVersion } = this.formData
      if (!plat && !appVersionCompare && !appVersion) {
        callback()
      } else if (!plat) {
        callback(new Error('请选择平台'))
      } else if (!appVersionCompare) {
        callback(new Error('请选择比较符号'))
      } else if (!appVersion) {
        callback(new Error('请填写版本号'))
      } else {
        callback()
      }
    }
    return {
      gamePlatList,
      computeSignList,
      formData: {
        dateRange: [
          format(new Date(new Date().toLocaleDateString()).getTime() - 24 * 60 * 60 * 1000, dateFormat),
          format(new Date(new Date().toLocaleDateString()).getTime(), dateFormat)
        ],
        type: 'resource'
      },
      chartData: {},
      options: [
        {
          value: 'resource',
          name: '资源占用时间'
        },
        {
          value: 'heartbeat',
          name: '心跳时间'
        }
      ],
      loading: false,
      remoteLoading: false,
      rules: {
        appVersion: [{ validator: appVersionCheck }]
      }

    }
  },
  mounted () {
    this.queryMachineConnectRate()
  },
  methods: {
    queryMachineConnectRate () {
      const [start, end] = this.formData.dateRange
      const { plat, appVersion, appVersionCompare } = this.formData
      /* eslint-disable  camelcase */
      const start_time = format(new Date(start), dateFormat)
      const end_time = format(new Date(end), dateFormat)
      const type = this.formData.type
      this.loading = true
      getSankeyeData({
        start_time,
        end_time,
        type,
        plat,
        appVersion,
        appVersionCompare
      })
        .then(res => {
          const { links, data, descs } = res.data
          this.chartData = {
            data,
            links,
            descs
          }
          this.$nextTick(() => {
            this.$refs.chart.setOptions()
          })
        })
        .finally(() => {
          this.loading = false
        })
    },
    handleViewLog () {
      this.$router.push({
        name: 'UserLinkStatistics'
      })
    },
    remoteMethod (query) {
      if (!query) return
      this.remoteLoading = true
      searchGameList({ keyWord: query, pageNum: 1, pageSize: 30 })
        .then(res => {
          if (res.code === 200) {
            this.options = (res.data.list || []).map(item => {
              return {
                gameId: item.gameIdStr,
                gameName: item.gameName
              }
            })
          }
        })
        .finally(() => {
          this.remoteLoading = false
        })
    }
  }
}
</script>

<style lang="less">
  .Dashboard-layout{
    .layout-content{
      display: flex;
      flex-direction: column;
      .wrapper{
        flex: 1;
        display: flex;
        .chart{
          flex: 1;
        }
      }
    }
  }
</style>
