<template>
  <div
    :class="[className, 'line-charts-wrapper']"
    :style="{height:height,width:width}"
  />
</template>

<script>

import * as echarts from 'echarts'
import resize from '@/mixins/resize'

export default {
  name: 'SankeyChart',
  mixins: [resize],
  props: {
    className: {
      type: String,
      default: 'chart'
    },
    width: {
      type: String,
      default: '100%'
    },
    height: {
      type: String,
      default: '450px'
    },
    autoResize: {
      type: Boolean,
      default: true
    },
    chartData: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      chart: null
    }
  },
  // watch: {
  //   chartData: {
  //     deep: true,
  //     handler (val) {
  //       this.setOptions(val)
  //     }
  //   }
  // },
  mounted () {
    this.initChart()
  },
  beforeDestroy () {
    if (!this.chart) {
      return
    }
    this.chart.dispose()
    this.chart = null
  },
  methods: {
    initChart () {
      this.chart = echarts.init(this.$el)
      // this.setOptions()
    },
    setOptions () {
      this.chart.clear()
      const {
        data,
        links,
        descs: persentMap
      } = this.chartData
      this.chart.setOption({
        tooltip: {
          trigger: 'item',
          triggerOn: 'mousemove',
          formatter: (val) => {
            const { data } = val
            return `
            Name: ${data.name || data.target} <br />
            Count: ${persentMap[data.name || data.target].self_num} <br />
            Parent Count: ${persentMap[data.name || data.target].parent_num} <br />
            Ratio: ${persentMap[data.name || data.target].percent}
            `
            // if (data.name) {
            //   return `${data.name} ${value} <br/> ${persentMap[data.name].percent || 'unknown'}`
            // } else {
            //   return `${data.source} > ${data.target} ${value} <br/> ${persentMap[data.target].percent || 'unknown'}`
            // }
          }
        },
        toolbox: {
          right: 50
          // feature: {
          //   // restore: { show: true }
          // }
        },
        series: [
          {
            type: 'sankey',
            data,
            links,
            nodeAlign: 'left',
            focusNodeAdjacency: true,
            animationDuration: 1500,
            animationEasing: 'linear',
            emphasis: {
              focus: 'adjacency'
            },
            lineStyle: {
              color: 'gradient',
              curveness: 0.5
            }
          }
        ]
      })
    }
  }
}
</script>

<style scoped lang="less">
  .line-charts-wrapper {
    box-sizing: border-box;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, .12);
  }
</style>
